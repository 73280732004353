import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '../views/layout/Layout'

export const constantRouterMap = [
  {
    path: '/login',
    component: () => import(/* webpackPrefetch: true */ '@/views/account/Login'),
    hidden: true
  },
  {
    path: '/signup',
    component: () => import(/* webpackPrefetch: true */ '@/views/account/Signup'),
    hidden: true
  },
  {
    path: '/forgotpassword',
    component: () => import(/* webpackPrefetch: true */ '@/views/account/ForgotPassword'),
    hidden: true
  },
  {
    path: '/changepassword',
    component: () => import(/* webpackPrefetch: true */ '@/views/account/ChangePassword'),
    hidden: true
  },
  { path: '/404', component: () => import(/* webpackPrefetch: true */'@/views/404'), hidden: true },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    name: 'Dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import(/* webpackPrefetch: true */ '@/views/dashboard/Grid'),
        meta: { title: 'Dashboard', icon: 'dashboard1', noCache: true }
      }
    ]
  },
  {
    path: '/list',
    component: Layout,
    redirect: '/patient-case',
    children: [
      {
        path: '/list/:patientId',
        name: 'Patient Case',
        props: true,
        component: () => import(/* webpackPrefetch: true */ '@/views/dashboard/List'),
        meta: { title: 'Patient Case', icon: 'dashboard1', noCache: true, patientId: 1 }
      },
      {
        path: '/list/index',
        props: true,
        component: () => import(/* webpackPrefetch: true */ '@/views/dashboard/List'),
        meta: { title: 'Patient Case', icon: 'dashboard1', noCache: true, patientId: 1 }
      }
    ]
  },
  {
    path: '/active-monitoring',
    component: Layout,
    redirect: '/active-monitoring',
    children: [
      {
        path: 'index/:patientId',
        name: 'Active Monitoring',
        props: true,
        component: () => import(/* webpackPrefetch: true */'@/views/activeMonitoring/ActiveMonitoring'),
        meta: { title: 'Active Monitoring', icon: '', noCache: true, patientId: 1 }
      }
    ]
  },
  {
    path: '/ecg-Events',
    component: Layout,
    redirect: '/ecg-Events',
    children: [
      {
        path: 'index',
        name: 'EcgEvents',
        props: true,
        component: () => import(/* webpackPrefetch: true */'@/views/ecgEvents/Index'),
        meta: { title: 'EcgEvents', icon: '', noCache: true }
      }
    ]
  },
  {
    path: '/video-consultations',
    component: Layout,
    redirect: '/video-consultations',
    children: [
      {
        path: 'index',
        name: 'Video Consultations',
        component: () => import(/* webpackPrefetch: true */'@/views/videoConsultation/AllConsultations'),
        meta: { title: 'Video Consultations', icon: '', noCache: true }
      }
    ]
  },
  { path: '*', redirect: '/404', hidden: true }
]

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})
