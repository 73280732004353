const videoConsultations = {
  state: {
    schedule: false
  },
  mutations: {
    SET_SCHEDULE_INFO: (state, data) => {
      state.schedule = data
    }
  },
  actions: {
    setSchedule({ commit }, data) {
      commit('SET_SCHEDULE_INFO', data)
    }
  }
}

export default videoConsultations

