import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import vitalRanges from './modules/vitalRanges'
import sidePanel from './modules/sidePanel'
import videoConsultations from './modules/videoConsultations'
import reportSummury from './modules/reportSummury'
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
    vitalRanges,
    sidePanel,
    reportSummury,
    videoConsultations
  },
  getters
})

export default store
