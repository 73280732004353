import axios from 'axios'
// import { Message, MessageBox } from 'element-ui'
import { Message } from 'element-ui'
import store from '../store'
import { getToken, getBusinessPartner } from '@/utils/auth'

const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL, // api base_url
  timeout: 15000
})

// request
service.interceptors.request.use(
  (config) => {
    if (store.getters.token) {
      config.headers['Authorization'] = `Bearer ${getToken()}`
      config.headers['business'] = `${getBusinessPartner()}`
    }
    return config
  },
  (error) => {
    // Do something with request error
    console.error(error) // for debug
    Promise.reject(error)
  }
)

// response
service.interceptors.response.use(
  (response) => {
    const res = response.data
    if (response.status !== 200 || response.status !== 201) {
      return response.data
    } else {
      Message({
        message: res.message,
        type: 'error',
        duration: 5 * 1000
      })
      Promise.reject('error')
    }
  },
  (error) => {
    // console.error("err" + error); // for debug
    // Message({
    //   message: error.message,
    //   type: "error",
    //   duration: 5 * 1000
    // });
    // return Promise.reject(error);
    const errorMsg =
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors &&
      error.response.data.errors.length > 0 &&
      error.response.data.errors[0] &&
      error.response.data.errors[0].messages &&
      error.response.data.errors[0].messages.length > 0
        ? error.response.data.errors[0].messages[0]
        : error.response.data.message
          ? error.response.data.message
          : error.message
    return Promise.reject(errorMsg)
  }
)

export default service
