import request from '@/utils/request'

export function login(data) {
  return request({
    url: 'auth/login',
    method: 'post',
    data: data
  })
}

export function platformUserLogin(data) {
  return request({
    url: 'auth/nurseLogin',
    method: 'post',
    data: data
  })
}

export function signup(data) {
  return request({
    url: '/users/signup',
    method: 'post',
    data: data
  })
}

export function change_password(data) {
  return request({
    url: '/users/changepassword',
    method: 'put',
    data: data
  })
}

export function getInfo() {
  return request({
    url: '/users/currentUserInfo',
    method: 'get'
  })
}

export function logout() {
  return request({
    url: '/users/signout',
    method: 'post'
  })
}
