import { requestUrl } from '@/api/apiq.js'

const vitalRanges = {
  state: {
    vitalRanges: {},
    caseMap: {},
    caseDetails: null
  },
  mutations: {
    SET_VITAL_RANGES: (state, formObj) => {
      state.vitalRanges = formObj
    },
    SET_CASE_MAP: (state, formObj) => {
      state.caseMap = formObj
    },
    SET_CASE_DETAILS: (state, payload) => {
      state.caseDetails = payload
    },
    UPDATE_MONITORING_MODE: (state, payload) => {
      state.caseDetails.condition.activeMonitoringMode.value = payload
    }
  },
  actions: {
    setVitalRanges({ commit }, data) {
      commit('SET_VITAL_RANGES', data)
    },
    setCaseMap({ commit }, data) {
      commit('SET_CASE_MAP', data)
    },
    updateCaseMonitoringMode({ commit }, data) {
      commit('UPDATE_MONITORING_MODE', data)
    },
    async getCaseDetails({ commit }, id) {
      await requestUrl(`case/${id}`, {}, 'get')
        .then(
          (data) => {
            if (data) {
              commit('SET_CASE_DETAILS', data)
            }
          },
          (err) => {
            console.error('err', err)
          }
        )
        .catch((err) => {
          console.error('err', err)
        })
    }
  }
}

export default vitalRanges
