import {
  login,
  getInfo,
  change_password,
  platformUserLogin
} from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import i18n from '@/localization/i18n'

const user = {
  state: {
    password: '',
    userId: '',
    token: getToken(),
    firstName: '',
    lastName: '',
    name: '',
    email: '',
    avatar: '',
    roles: [],
    isShowDrawer: false,
    isDoctorAvailable: true,
    socketChannel: '',
    userPreferences: {},
    lang: '',
    platformPartner: '',
    appointmentId: '',
    alerts: [],
    businessPartner: '',
    waitingRooms: [],
    caseVitalsList: [],
    lastVitalInCase: 'TEMP',
    layout: [],
    customerId: '',
    isTable: true
  },

  mutations: {
    SET_USER_ID: (state, userId) => {
      state.userId = userId
      if (userId !== undefined) {
        localStorage.setItem('userId', userId)
      }
    },
    SET_USER_PASSWORD: (state, password) => {
      state.password = password
    },
    SET_LAYOUT: (state, layout) => {
      state.layout = layout
      if (layout !== undefined) {
        localStorage.setItem('layout', JSON.stringify(layout))
      }
    },
    SET_CUSTOMER_ID: (state, customerId) => {
      state.customerId = customerId
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_FIRST_NAME: (state, firstName) => {
      state.firstName = firstName
      if (firstName !== undefined) {
        localStorage.setItem('firstName', firstName)
      }
    },
    SET_USER_PREFERENCES: (state, userPreferences) => {
      state.userPreferences = userPreferences
      state.lang = userPreferences.language
      i18n.locale = userPreferences.language
      if (userPreferences !== undefined) {
        localStorage.setItem('lang', userPreferences.language)
        localStorage.setItem(
          'UserPreferences',
          JSON.stringify(userPreferences)
        )
      }
    },
    SET_PLATFORM_PARTNER: (state, platformPartner) => {
      state.platformPartner = platformPartner
      if (platformPartner !== undefined) {
        localStorage.setItem('platformPartner', platformPartner)
      }
    },
    SET_BUSINESS_PARTNER: (state, businessPartner) => {
      state.businessPartner = businessPartner
      if (businessPartner !== undefined) {
        localStorage.setItem('businessPartner', businessPartner)
      }
    },
    SET_LAST_NAME: (state, lastName) => {
      state.lastName = lastName
      if (lastName !== undefined) {
        localStorage.setItem('lastName', lastName)
      }
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_EMAIL: (state, email) => {
      state.email = email
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_SOCKET_CHANNEL: (state, socketChannel) => {
      state.socketChannel = socketChannel
      if (socketChannel !== undefined) {
        localStorage.setItem('socketChannel', socketChannel)
      }
    },
    SET_IS_TABLE: (state, isTable) => {
      state.isTable = isTable
    }
  },

  actions: {
    setIsTable({ commit }, channel) {
      commit('SET_IS_TABLE', channel)
    },
    setUserPassword({ commit }, password) {
      commit('SET_USER_PASSWORD', password)
    },
    Login({ commit }, userInfo) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        login(userInfo).then((response) => {
          const data = response
          setToken(data.accessToken)
          commit('SET_FIRST_NAME', data.user.firstName)
          commit('SET_LAST_NAME', data.user.lastName)
          commit('SET_NAME', `${data.user.firstName} ${data.user.lastName}`)
          commit('SET_TOKEN', data.accessToken)
          commit('SET_USER_ID', data.user.id)
          commit('SET_EMAIL', data.user.email)
          resolve(response)
        })
      })
    },

    platformLogin({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        platformUserLogin(userInfo)
          .then((response) => {
            const data = response
            setToken(data.accessToken)
            localStorage.setItem('userDetails', JSON.stringify(data.user))
            commit('SET_FIRST_NAME', data.user.firstName)
            commit('SET_LAST_NAME', data.user.lastName)
            commit('SET_NAME', `${data.user.firstName} ${data.user.lastName}`)
            commit('SET_TOKEN', data.accessToken)
            commit('SET_USER_ID', data.user.id)
            commit('SET_EMAIL', data.user.email)
            if (data.user.business && data.user.business.layout) {
              commit('SET_LAYOUT', data.user.business.layout)
            } else {
              commit('SET_LAYOUT', [])
            }
            commit('SET_PLATFORM_PARTNER', data.user.platformPartner)
            commit('SET_BUSINESS_PARTNER', data.user.business.id)
            commit(
              'SET_SOCKET_CHANNEL',
              '/' + data.user.platformPartner + '/' + data.user.role
            )
            resolve(response)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    Changepassword({ commit }, userInfo) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        change_password(userInfo)
          .then((response) => {
            const data = response.data
            setToken(data.token)
            commit('SET_TOKEN', data.token)
            resolve(response)
          })
          .catch((err) => {
            return err
          })
      })
    },

    // eslint-disable-next-line no-unused-vars
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((response) => {
            const data = response.data
            console.info(`==============Getinfo >> `, data)
            commit('SET_USER_ID', data.id)
            commit('SET_ROLES', [data.role])
            commit('SET_FIRST_NAME', data.firstName)
            commit('SET_LAST_NAME', data.lastName)
            commit('SET_NAME', `${data.firstName} ${data.lastName}`)
            commit('SET_AVATAR', data.avatar)
            commit('SET_EMAIL', data.email)
            commit('SET_SOCKET_CHANNEL', data.socketChannel)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // eslint-disable-next-line no-unused-vars
    LogOut({ commit, state }) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        // logout()
        //   .then(() => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        localStorage.removeItem('socketChannel')
        removeToken()
        localStorage.clear()
        resolve()
        // })
        // .catch(error => {
        //   reject(error);
        // });
      })
    },

    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
