import request from '@/utils/request'
// eslint-disable-next-line no-unused-vars
export function list(path, paginationInfo = null) {
  return request({
    url: `/${path}`,
    method: 'get'
  })
}

export function search(path, query) {
  return request({ url: `/${path}?${query}`, method: 'get' })
}

export function details(path, id, query = '') {
  return request({ url: `/${path}/${id}?${query}`, method: 'get' })
}

export function edit(path, id) {
  return request({ url: `/${path}/${id}/edit`, method: 'post' })
}

export function create(path, data) {
  return request({ url: `/${path}`, method: 'post', data: data })
}

export function mapDeviceInventory(path, data) {
  return request({ url: `/${path}`, method: 'post', data: data })
}

export function save(path, data, id = '') {
  return request({
    url: `${path}/${id}`,
    method: id ? 'patch' : 'post',
    data: data
  })
}

export function requestUrl(url, data = {}, method) {
  return request({
    url: url,
    method: method,
    data: data
  })
}

export function update(path, data, id) {
  return request({
    url: `/${path}/${id}`,
    method: 'put',
    data: data
  })
}

export function remove(path, id) {
  return request({ url: `/${path}/${id}`, method: 'delete' })
}
