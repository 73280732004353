const reportSummury = {
  state: {
    isReportSummury: false,
    reportDetails: null,
    pdfReport: null,
    chartBase64: null,
    events: [],
    reportStatus: false,
    isUploaded: null
  },
  mutations: {
    SET_IS_REPORT_SUMMURY: (state, data) => {
      state.isReportSummury = data
    },
    SET_REPORT_DETAILS: (state, data) => {
      state.reportDetails = data
    },
    SET_PDF_REPORT: (state, data) => {
      state.pdfReport = data
    },
    SET_CHART_BASE64: (state, data) => {
      state.chartBase64 = data
    },
    SET_VITAL_CHART_BASE64: (state, data) => {
      const current = state.chartBase64.find(v => {
        if (v.timeStamp === data.date) {
          return v
        }
      })
      if (current.timeStamp === data.date) {
        const image = { image: data.image, vital: data.vital }
        current.chartBase64.push(image)
      }
      state.isUploaded = data.isUploaded
    },
    SET_EVENTS_BASE64: (state, data) => {
      if (data === null) { state.events = []; return }
      state.events.push(data)
    },
    SET_IS_REPORT_STATUS: (state, data) => {
      state.reportStatus = data
    },
    SET_IS_UPLOADED: (state, data) => {
      state.isUploaded = data
    }
  },
  actions: {
    setReport({ commit }, data) {
      commit('SET_IS_REPORT_SUMMURY', data.isReportSummury)
      commit('SET_IS_REPORT_STATUS', data.status)
    },
    setReportDetails({ commit }, data) {
      commit('SET_REPORT_DETAILS', data)
    },
    setPdfReport({ commit }, data) {
      commit('SET_PDF_REPORT', data)
    },
    setChartBase64({ commit }, data) {
      commit('SET_CHART_BASE64', data)
    },
    setVitalChartBase64({ commit }, data) {
      commit('SET_VITAL_CHART_BASE64', data)
    },
    setEventsBase64({ commit }, data) {
      commit('SET_EVENTS_BASE64', data)
    },
    setIsuploaded({ commit }, data) {
      commit('SET_IS_UPLOADED', data)
    }
  }
}

export default reportSummury

